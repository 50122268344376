<template>
	<div class="container">
		<el-carousel indicator-position="outside" :height=" bannerHeight + 'px' " :autoplay="false">
			<el-carousel-item v-for="item in carousel" :key="item.bannerId">
				<img v-if="item.bannerType == 1" :src="item.bannerImage" alt="" style="width: 100%; height: 100%;"
					@click="imgClick(item.jumpId)">
				<video v-else id="video2" autoplay muted controls loop
					style="width: 100%; height: 100%; object-fit: cover;" @click="imgClick(item)">
					<!-- 这里是视频源 -->
					<source :src="item.bannerImage" type="video/mp4">
					您的浏览器不支持HTML5视频播放功能。
				</video>
			</el-carousel-item>
		</el-carousel>
		<!-- <div class="introduce">
			<img src="../../assets/imgs/home.png" alt="">
			<div class="introduce-text">
				<div class="introduce-top">
					<div class="line1"></div>
					<div class="point"></div>
					<p class="name">天乾地坤</p>
					<div class="point"></div>
					<div class="line"></div>
				</div>
				<div class="introduce-bottom">
					引领产业数字化，建设绿色智慧城市
				</div>
			</div>
			<div class="introduce-nav">
				<div @click="handleOpenVideo" class="introduce-nav-active">
					<img src="../../assets/imgs/home-icon-video.png" alt="">
					<p>观看视频</p>
					<img class="yy" src="../../assets/imgs/yy.png" alt="">
				</div>
				<div @click="handleClickTo('/littleDoorGods')">
					<img  src="../../assets/imgs/home-icon-plan.png" alt="">
					<p>查看方案</p>
					<img class="yy" src="../../assets/imgs/yy.png" alt="">
				</div>
				<div @click="showZixun = true">
					<img src="../../assets/imgs/home-icon-message.png" alt="">
					<p>项目咨询</p>
					<img class="yy" src="../../assets/imgs/yy.png" alt="">
				</div>
			</div>
		</div> -->
		<div class="impotMain" v-if="oneShow">
			<h4 class="title">主营业务</h4>
			<div class="box">
				<div class="imgBox" v-for="(item,index) of businessInfo" @click="importClick(item)">
					<div class="textBox">
						<span>{{item.bannerName}}</span>
					</div>
					<img :src="item.bannerImage" alt="">

				</div>
			</div>
		</div>
		<div class="hot-solution" v-if="twoShow">
			<h4 class="title">热门方案</h4>
			<p class="subtitle">Popular programs</p>
			<div ref="mySwiper" class="swiper-container swiper-container-first" id="currentIndex">
				<div class="swiper-wrapper">
					<div class="swiper-slide my-swiper-slide swiper-slide-first" v-for="item of perInfo" :key="item.id"
						@click="routeClick(item)">
						<img class="swiper-img" :src="item.imageUrl" alt="">
						<div style="background-color: #F4F4F4;width: 318px;margin: -27px auto 0;padding-top: 26px;">
							<p class="title">{{item.title}}</p>
							<p class="content">{{item.info}}</p>
						</div>
					</div>
				</div>
				<!-- 分页器 -->
				<div class="swiper-pagination"></div>

			</div>
		</div>

		<div class="successful-cases" v-if="threeShow">
			<div class="headline">
				<p class="title">产品信息</p>
			</div>
			<div ref="mySwiper" class="swiper-container swiper-container-sec" id="caseSwiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide my-swiper-slide swiper-slide-sec" v-for="item of productInfo"
						:key="item.id" @click="routeClick(item)">
						<img :src="item.imageUrl" alt="">
						<div class="swiper-content">
							<p class="title">{{item.title}}</p>
							<p class="content">{{item.info}}</p>
						</div>
					</div>
				</div>
				<!-- 分页器 -->
				<div class="swiper-pagination"></div>
				<!--导航器-->

			</div>
			<div class="swiper-button-prev"></div>
			<div class="swiper-button-next"></div>
		</div>

		<div class="institutions">
			<div class="text">
				<p class="title">研发机构</p>
				<p class="subtitle">天乾地坤在承德、成都、石家庄设立了3个研发机构，承担不同方向技术和产品的研发</p>
			</div>
			<div class="institutions-nav">
				<p @click="handleInActiveBtn(item.id)"
					:class="{'institutions-nav-active': institutionsNavActive == item.id}"
					v-for="item of institutionsNav" :key="item.id">
					{{item.name}}
				</p>
			</div>
			<div class="institutions-img">
				<div :class="{'institutions-img-active': institutionsNavActive == item.id}"
					v-for="item of institutionsArr" :key="item.id" class="institutions-img-content">
					<img :src="item.img" alt="">
					<div class="text">
						<p class="title">{{item.title}}</p>
						<p class="content">{{item.content}}</p>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="partners">
			<img class="partners-img" src="../../assets/imgs/partners.png" alt="">
			<div class="right">
				<p class="title">合作伙伴</p>
				<p class="content">天乾地坤诚挚邀请解决方案集成商、物联网硬件厂商、智慧场景建设方、智慧场景运营方、解决方案代理商共同合作探讨，提升研发技术水平，共建产业元宇宙。</p>
				<p class="line"></p>
				<p class="btn" @click="dialog = true">联系我们</p>
			</div>
		</div> -->
		<div class="hot-solution" v-if="fourShow">
			<h4 class="title">新闻动态</h4>
			<div ref="mySwiper" class="swiper-container swiper-container-first" id="newIndex">
				<div class="swiper-wrapper">
					<div class="swiper-slide my-swiper-slide swiper-slide-first" v-for="item of trendsInfo"
						:key="item.id" @click="routeClick(item)">
						<img class="swiper-img" :src="item.imageUrl" alt="">
						<div style="background-color: #F4F4F4;width: 318px;margin: -27px auto 0;padding-top: 26px;">
							<p class="title">{{item.title}}</p>
							<p class="content">{{item.info}}</p>
						</div>
					</div>
				</div>
				<!-- 分页器 -->
				<div class="swiper-pagination"></div>

			</div>
		</div>
		<el-dialog title="申请合作" :visible.sync="dialog" width="900px">

			<el-form :model="dialogForm"
				style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;"
				label-width="100px">
				<el-form-item label="公司名称:">
					<el-input v-model="dialogForm.name" style="width: 300px;" placeholder="请输入公司名称"></el-input>
				</el-form-item>
				<el-form-item label="所在城市:">
					<el-input v-model="dialogForm.city" style="width: 300px;" placeholder="请输入公司名称"></el-input>
				</el-form-item>
				<el-form-item label="联系人:">
					<el-input v-model="dialogForm.contact" style="width: 300px;" placeholder="请输入公司名称"></el-input>
				</el-form-item>
				<el-form-item label="联系电话:">
					<el-input v-model="dialogForm.phone" style="width: 300px;" placeholder="请输入公司名称"></el-input>
				</el-form-item>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button @click="dialog = false">取 消</el-button>
				<el-button type="primary" @click="dialog = false">确 定</el-button>
			</div>
		</el-dialog>

		<div v-show="showZixun" @click.stop="showZixun = false" class="zixun">
			<img @click.stop="" src="../../assets/imgs/zixun.png" alt="">
		</div>

		<!-- <div v-show="videoShow" @click="handleCloseVide" class="video">
			<video @click.stop="" style="width: 50%;height: 50%;"
				src="https://pic.cklock.cn/administration/22e00202207310937556106.mp4" controls ref="videoRef"></video>
		</div> -->
	</div>
</template>

<script>
	import { getDHpage } from "@/api/home";
	import Swiper from 'swiper'
	import "swiper/css/swiper.css";
	import countTo from 'vue-count-to'
	export default {
		components: {
			countTo,
		},
		data() {
			return {
				oneShow: true,
				twoShow: true,
				threeShow: true,
				fourShow: true,
				businessInfo: [],
				perInfo: [],
				productInfo: [],
				trendsInfo: [],
				bannerHeight: 100,//图片父容器的高度
				screenWidth: 0,//屏幕的宽度
				carousel: [],
				showZixun: false,
				videoShow: false,
				hotSwiper: [
					{
						id: '1',
						title: '数字城市',
						content: '天乾地坤数字城市平台聚合家庭、社区、城市、物业、行政部门等角色，融合人工智能、云计算、大数据、地理信息、视频、…',
						img: require('@/assets/imgs/city.png')
					},
					{
						id: '2',
						title: '城市更新',
						content: '随着生态文明建设大力推进，未来城市将朝着绿色方向发展。在城市更新过程中，不仅是重新对城市进行生态修复重构生…',
						img: require('@/assets/imgs/ren.png')
					},
					{
						id: '3',
						title: '乡村振兴',
						content: '打造“数字乡村+一网协同”新平台，围绕数字乡村，建设惠农、兴农、助农、优政、善治等目标，建设互联互通、数据共建共享…',
						img: require('@/assets/imgs/vita.png')
					},
					{
						id: '4',
						title: '全要素疫情防控',
						content: '针对紧急状态下，智慧隔离服务系统、疫情防控追踪系统及所需的小门神智能门磁、超低能耗核酸采样室，人脸识别测温，多人热成像…',
						img: require('@/assets/imgs/concon.png')
					},
					{
						id: '5',
						title: '智慧教体',
						content: '智慧教体是指依托计算机和教育网,全面深入地利用以物联网、云计算等为代表的新兴信息技术,重点建设教育信息化基础设施,开发和利…',
						img: require('@/assets/imgs/style.png')
					},
					{
						id: '6',
						title: '综合能源',
						content: '规划、建设和运行等过程中，通过对能源的产生、传输与分配（能源网络）、转换、存储、消费等环节进行有机协调与优化后，形成的…',
						img: require('@/assets/imgs/emer.png')
					},
				],

				caseSwiperArr: [
					{
						id: '1',
						title: '赛罕云pass平台',
						content: '塞罕云PAAS平台（融合智慧社区、智慧酒店、疫情防控、公租房、智能家居、直播等系统），它是物联网本地化生活服务平台将数字经济和实体经济深度融合，融合物联网、大数据、云计算、AI深度学习等新兴技术，对标合作华为、网易、涂鸦等业内知名企业，开辟政府、商业、金融、生活四大平台板块，涵盖智慧家居、外卖、直播、电商、酒店、共享充电宝、缴费充值、社区党建、社区服务等多个领域，打通家庭…',
						img: require('@/assets/imgs/case1.png')
					},
					{
						id: '2',
						title: '智能疫情防控设备',
						content: '随着疫情防控进入常态化，由于人员是具有流动性特征的，为解决如何记录人员的行动轨迹，并且把行动轨迹留痕上报是疫情防控急需解决的一大难题，我司针对人特有的流动性特征，结合多人热成像人脸识别测温设备，研发出一款“智能疫情防控追踪服务系统”',
						img: require('@/assets/imgs/case2.png')
					}, {
						id: '3',
						title: '智慧家庭案例',
						content: '系统概述：综合利用计算机、网络通讯、家电控制等技术，将家庭智能控制、信息交流及消费服务等家居生活有效结合起来，创造高效、舒适、安全、便捷的个性化家居生活',
						img: require('@/assets/imgs/case3.png')
					}, {
						id: '4',
						title: '智慧酒店升级改造项目',
						content: '智慧酒店系统基于微信平台开发的创新型智慧酒店综合服务管理平台。它包括酒店客户下单、入住、酒店设备智能化交互、酒店客房服务等。使用该系统将大大降低酒店管理运营成本，提高酒店服务质量，给客户带来更舒适、时尚、便捷和科技感的入住体验。',
						img: require('@/assets/imgs/case4.png')
					}, {
						id: '5',
						title: '承德公租房改造项目',
						content: '近年来，我国开始探索住房保障体系建设，并取得了一定成就。继廉租房、经适房和限价房后,为解决社会"夹心层"住房难问题政府提出了一个全新的住房保障模式--公共租赁住房，简称公租房。当前我国公租房建设正处于初步探索阶段，为促进我国公租房体系的自我完善,实现又好又快发展，本文对当前公租房管理中存在的若干问题作了浅析,并结合本公司科技技术手段提供了相应的解决方案。',
						img: require('@/assets/imgs/case5.png')
					},
				],

				terminalStart: 0,
				terminalEnd: 10,
				userStart: 0,
				userEnd: 50,
				cishuStart: 0,
				cishuEnd: 7.89,
				institutionsNav: [
					{
						id: '1',
						name: '承德'
					},
					{
						id: '2',
						name: '成都'
					},
					{
						id: '3',
						name: '石家庄'
					}
				],
				institutionsNavActive: '1',
				institutionsArr: [
					{
						id: '1',
						title: '承德',
						content: '物联网、智能硬件研发、生产。',
						img: require('@/assets/imgs/ins1.png')
					}, {
						id: '2',
						title: '成都',
						content: '塞罕云paas平台 、数字城市、数字乡村、综合能源等领域',
						img: require('@/assets/imgs/ins2.png')
					}, {
						id: '3',
						title: '石家庄',
						content: '疫情防控平台及定制化项目开发',
						img: require('@/assets/imgs/ins3.png')
					},
				],
				dialog: false,
				dialogForm: {
					name: '',
					city: '',
					contact: '',
					phone: ''
				}
			}
		},

		mounted() {
			this.screenWidth = window.innerWidth;
			this.setSize();
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};

			const videos = document.querySelectorAll("video")
			console.log(videos)
			videos.forEach(video => {
				// 阻止单击暂停
				video.addEventListener("click", (event) => {
					event.preventDefault();
				});
			});
			var _this = this

			// setTimeout(function () {
			// }, 2000)
			getDHpage().then(response => {
				const data = response.data
				this.businessInfo = data.businessInfo
				this.perInfo = data.perInfo
				this.productInfo = data.productInfo
				this.trendsInfo = data.trendsInfo
				this.carousel = data.carousel
				localStorage.businessInfo = JSON.stringify(data.businessInfo)
				localStorage.perInfo = JSON.stringify(data.perInfo)
				localStorage.productInfo = JSON.stringify(data.productInfo)
				localStorage.trendsInfo = JSON.stringify(data.trendsInfo)
				localStorage.carousel = JSON.stringify(data.carousel)
				// localStorage.footInfo = JSON.stringify(data.footInfo)
				// localStorage.navigation = JSON.stringify(data.navigation)
				console.log(this.businessInfo)
				if (this.businessInfo.length == 0) {
					this.oneShow = false
				}
				if (this.perInfo.length == 0) {
					this.twoShow = false
				}
				if (this.productInfo.length == 0) {
					this.threeShow = false
				}
				if (this.trendsInfo.length == 0) {
					this.fourShow = false
				}
				console.log(data)
				console.log(this.carousel, 123)

				setTimeout(function () {
					_this.initSwiper()
					_this.onitSwiper()
					_this.caseSwiper()
				}, 200)
			})
		},

		methods: {
			importClick(item) {
				// this.$router.push({ path: '/wisdomProperty', query: { item: JSON.stringify(item) } }) //产品
				if (item.jumpUrl) {
					window.open(item.jumpUrl)
				} else {
					this.$router.push({ path: '/thermometer', query: { item: JSON.stringify(item) } }) //产品

				}
			},
			imgClick(i) {
				if (i == 198699) {
					this.$router.push('/home')
				}
				if (i == 198751) {
					this.$router.push('/mall')
				}
				if (i == 198753) {
					this.$router.push('/wisdomCampus')
				}
				if (i == 198757) {
					this.$router.push('/wisdomGreenhouses')
				}
				if (i == 198754) {
					this.$router.push('/wisdomPartySchool')
				}
				if (i == 198721) {
					this.$router.push('/wisdomFamily')
				}
				if (i == 198752) {
					window.open('https://tqdkjgs.cklock.cn/#/home')
				}
				if (i == 198756) {
					this.$router.push('/cooperation')
				}
				if (i == 198758) {
					this.$router.push('/about')
				}
			},
			routeClick(item) {
				this.$router.push({ path: '/wisdomProperty', query: { item: JSON.stringify(item) } }) //产品
			},
			setSize: function () {
				// 通过屏幕宽度(图片宽度)计算高度
				this.bannerHeight = 700 / 1920 * this.screenWidth;
			},
			initSwiper() {
				// eslint-disable-next-line
				let vueComponent = this//获取vue组件实例
				//一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
				this.currentSwiper = new Swiper('#currentIndex', {

					autoHeight: 'true',//开启自适应高度,容器高度由slide高度决定
					//分页器
					pagination: {
						el: '.swiper-pagination',
						clickable: true,//分页器按钮可点击
					},

					//导航器
					// navigation: {
					//   nextEl: '.swiper-button-next',
					//   prevEl: '.swiper-button-prev',
					// },

					slidesPerView: 3, //视口展示slide数1
					spaceBetween: 15
				})

			},

			onitSwiper() {
				// eslint-disable-next-line
				let vueComponent = this//获取vue组件实例
				//一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
				this.currentSwiper = new Swiper('#newIndex', {

					autoHeight: 'true',//开启自适应高度,容器高度由slide高度决定
					//分页器
					pagination: {
						el: '.swiper-pagination',
						clickable: true,//分页器按钮可点击
					},

					//导航器
					// navigation: {
					//   nextEl: '.swiper-button-next',
					//   prevEl: '.swiper-button-prev',
					// },

					slidesPerView: 3, //视口展示slide数1
					spaceBetween: 15
				})

			},
			caseSwiper() {
				//一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
				this.currentSwiper = new Swiper('#caseSwiper', {

					autoHeight: 'true',//开启自适应高度,容器高度由slide高度决定
					//分页器
					pagination: {
						el: '.swiper-pagination',
						type: 'progressbar'
					},

					//导航器
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},

					slidesPerView: 2, //视口展示slide数1
					spaceBetween: 0
				})
			},

			handleInActiveBtn(val) {
				this.institutionsNavActive = val
			},


			handleClickTo(val) {
				this.$router.push(val)
			},

			handleCloseVide() {
				this.videoShow = false
				this.$refs.videoRef.pause()
			},
			handleOpenVideo() {
				this.videoShow = true
				this.$refs.videoRef.play()
			}
		},


	}
</script>
<style lang="scss" scoped>
	.impotMain {
		width: 1200px;
		margin: 0 auto;
		text-align: center;
		padding: 117px 90px 91px;
		box-sizing: border-box;

		.box {
			.imgBox {
				display: inline-block;
				width: 320px;
				height: 300px;
				margin-right: 30px;
				margin-bottom: 30px;
				position: relative;

				&:nth-child(3n+3) {
					margin-right: 0;
				}

				img {
					width: 320px;
					height: 300px;
				}

				.textBox {
					font-size: 18px;
					font-weight: 600;
					height: 60px;
					line-height: 60px;
					width: 100%;
					text-align: center;
					white-space: nowrap;
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					position: absolute;
					bottom: 0;
					background: rgba(0, 0, 0, 0.4);
					color: #fff;
				}

			}
		}

		.title {
			font-size: 36px;
			font-family: SourceHanSansCN-Medium, SourceHanSansCN;
			font-weight: 500;
			color: #333333;
			display: inline-block;
			line-height: 2;
			border-bottom: 3px solid #245ECB;
			margin-bottom: 50px;
		}
	}

	/* //所有控件 */
	video::-webkit-media-controls-enclosure {
		display: none;
		background-color: brown;
		width: 0;
		height: 0;
	}

	.introduce {
		background-size: cover;
		position: relative;
		margin-bottom: 177px;
	}

	.introduce img {
		width: 100%;
	}

	.introduce-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		text-align: center;
	}

	.introduce-top {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
	}

	.introduce-top .name {
		font-size: 48px;
		font-family: SourceHanSansCN-Bold, SourceHanSansCN;
		font-weight: bold;
		white-space: nowrap;
	}

	.introduce-top .line {
		width: 217px;
		height: 7px;
		background: linear-gradient(225deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
		border-radius: 6px;
	}

	.introduce-top .point {
		width: 7px;
		height: 7px;
		background: #FFFFFF;
		border-radius: 50%;
		margin: 0 24px;
	}

	.introduce-bottom {
		font-size: 28px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
	}

	.introduce-nav {
		position: absolute;
		bottom: -60px;
		left: 50%;
		transform: translate(-50%, 0);
		display: flex;
		align-items: center;

	}

	.introduce-nav div {
		background-color: #fff;
		width: 223px;
		height: 90px;
		text-align: center;
		font-size: 20px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
	}

	.introduce-nav div:not(:last-child) {
		margin-right: 9px;
	}

	.introduce-nav div img {
		width: 46px;
		height: 46px;
	}

	.introduce-nav div p {
		height: 20px;
		line-height: 20px;
	}

	.introduce-nav-active {
		background-color: #245ECB !important;
		position: relative;
		top: -7px;
	}

	.introduce-nav-active p {
		color: #fff;
	}

	.hot-solution {
		width: 1200px;
		margin: 117px auto 91px;
		text-align: center;
		padding: 0 90px;
		box-sizing: border-box;
	}

	.hot-solution .title {
		height: 36px;
		font-size: 36px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #333333;
	}

	.hot-solution .subtitle {
		height: 22px;
		font-size: 18px;
		font-family: Helvetica;
		color: #999999;
		margin-top: 10px;
		text-transform: uppercase;
	}

	.swiper-container-first {
		height: 500px;
		margin-top: 61px;
	}

	.swiper-slide-first {
		width: 328px !important;
		margin-right: 15px !important;
	}

	.swiper-slide-first .swiper-img {
		width: 328px;
		height: 280px;
	}

	.swiper-slide-first div {
		padding: 9px 17px 21px;
		width: 338px;
		box-sizing: border-box;
	}

	.swiper-slide-first div .title {
		height: 24px;
		font-size: 18px;
		overflow: hidden;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #393939;
		text-align: left;
		margin-bottom: 10px;
	}

	.swiper-slide-first div .content {
		width: 285px;
		font-size: 14px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #333333;
		line-height: 22px;
		text-align: left;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		-webkit-line-clamp: 5;
		text-overflow: ellipsis;
	}

	.company-data {
		margin-top: 149px;
		display: flex;
		flex-direction: column;
		/* justify-content: center;
	 */
		align-items: center;
	}

	.company-data .data-introduce {
		width: 692px;
		height: 125px;
		background: #245ECB;
		border-radius: 7px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.data-introduce p {
		width: 525px;
		font-size: 28px;
		font-family: SourceHanSansCN-Bold, SourceHanSansCN;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 38px;
		text-align: center;
	}

	.data-content {
		display: flex;
		align-items: center;
		margin-top: 90px;
	}

	.data-content div {
		padding: 0 65px;
		text-align: center;
	}

	.data-content div p {
		/* height: 34px; */
		font-size: 34px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #245ECB;
	}

	.data-content div:not(:last-child) {
		border-right: 1px dotted #979797;
	}

	.icon {
		display: flex;
		align-items: center;
		margin-top: 18px;

	}

	.data-content .icon p {
		white-space: nowrap;
		font-size: 24px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #666666;
	}

	.icon img {
		width: 48px;
		height: 48px;
	}

	.data-num {
		font-size: 64px;
	}

	.successful-cases {
		margin-top: 149px;
		background: #F4F8FF;
		padding: 144px 0 97px 88px;
		display: flex;
		justify-content: flex-end;
		position: relative;
	}

	.successful-cases .headline {
		margin-right: 110px;

	}

	.successful-cases .headline .title {
		height: 36px;
		font-size: 36px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #333333;
	}

	.successful-cases .headline .subtitle {
		height: 18px;
		font-size: 15px;
		font-family: Helvetica;
		color: #999999;
		text-transform: uppercase;
		margin-top: 20px;
	}

	.swiper-container-sec {
		width: 70%;
		height: 388px;
		margin: 0;
		position: relative;

	}

	.successful-cases .swiper-button-prev:after {
		display: none;
	}

	.successful-cases .swiper-button-next:after {
		display: none;
	}

	.successful-cases .swiper-button-next {
		width: 48px;
		height: 48px;
		background-image: url('../../assets/imgs/right.png');
		background-size: cover;
		top: 62%;
		left: 25%;
	}

	.successful-cases .swiper-button-prev {
		width: 48px;
		height: 48px;
		background-image: url('../../assets/imgs/left.png');
		background-size: cover;
		top: 445px;
		left: 25%;
	}

	.successful-cases .swiper-pagination {
		top: 382px;
		left: 0px;
	}

	.swiper-slide-sec {
		width: 620px !important;
		height: 346px !important;
		position: relative;
	}

	.swiper-slide-sec img {
		width: 100%;
		height: 100%;

	}

	.swiper-content {
		position: absolute;
		left: 33px;
		bottom: 12px;
	}

	.swiper-content .title {
		height: 16px;
		font-size: 16px;
		font-family: SourceHanSansCN-Bold, SourceHanSansCN;
		font-weight: bold;
		color: #FFFFFF;
	}

	.swiper-content .content {
		width: 547px;
		height: 66px;
		font-size: 10px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 14px;
		margin-top: 8px;
	}


	.institutions {
		width: 1200px;
		margin: 127px auto 90px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.institutions .text .title {
		height: 36px;
		font-size: 36px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #333333;
	}

	.institutions .text .subtitle {
		height: 14px;
		font-size: 14px;
		font-family: SourceHanSansCN-Normal, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
		margin-top: 19px;
	}

	.institutions-nav {
		margin-top: 38px;
		display: flex;
		align-items: center;
	}

	.institutions-nav p {
		width: 91px;
		height: 24px;
		border-radius: 3px;
		border: 1px solid #979797;
		font-size: 12px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
		line-height: 24px;
		cursor: pointer;
	}

	.institutions-nav p:not(:last-child) {
		margin-right: 108px;
	}

	.institutions-nav-active {
		background: #245ECB;
		border: none;
		color: #fff !important;
	}

	.institutions-img {
		margin-top: 40px;
		display: flex;
		align-items: center;
	}

	.institutions-img-content {
		width: 310px;
		height: 312px;
		position: relative;
	}

	.institutions-img-content:not(:last-child) {
		margin-right: 30px;
	}

	.institutions-img-content img {
		width: 100%;
		height: 100%;
	}

	.institutions-img-content .text {
		position: absolute;
		left: 14px;
		bottom: 5px;
	}

	.institutions-img-content .text .title {
		height: 18px;
		font-size: 18px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #FFFFFF;
		text-align: left;
	}

	.institutions-img-content .text .content {
		width: 270px;
		height: 51px;
		font-size: 12px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #FFFFFF;
		text-align: left;
		margin-top: 10px;
	}

	.institutions-img-active {
		position: relative;
		top: -7px;
	}

	.partners {
		margin: 170px auto 91px;
		padding: 0 100px;
		width: 1200px;
		box-sizing: border-box;
		display: flex;
		/* justify-content: center; */
		justify-content: space-between;
	}

	.partners-img {
		width: 448px;
		height: 426px;
	}

	.partners .right {
		text-align: right;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.partners .right .title {
		height: 36px;
		font-size: 36px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #333333;
	}

	.partners .right .content {
		width: 423px;
		font-size: 14px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #666666;
		margin: 20px 0;
	}

	.partners .right .line {
		width: 50px;
		height: 3px;
		background: #184BAB;
		margin-bottom: 48px;
	}

	.partners .right .btn {
		width: 108px;
		height: 30px;
		border-radius: 15px;
		border: 1px solid #1846AE;
		line-height: 30px;
		text-align: center;
		font-size: 12px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #245ECB;
		cursor: pointer;
	}

	.line1 {
		width: 217px;
		height: 7px;
		background: linear-gradient(135deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
		border-radius: 6px;
	}

	.zixun {
		width: 100vw;
		height: 100vh;
		background-color: rgba(188, 188, 188, .5);
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
	}



	.video {
		width: 100vw;
		height: 100vh;
		background-color: rgba(188, 188, 188, .5);
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
	}

	.yy {
		width: 246px !important;
		height: 111px !important;
		position: relative;
		left: -10px;
		top: -40px;
		z-index: -1;
	}
</style>